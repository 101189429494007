import styles from './styles.module.scss';

export function August1PagePT() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <section className={styles.welcome}>
                    <img src="/images/news/july3/cover1.png" alt="" />
                    <img src="/images/news/july3/title1-pt.png" alt="" />
                    <div className={styles.textsTitle}>
                        <p className={styles['texts-july']}>
                            Nesta edição você saberá como a Sthorm vai conectar artistas, público e
                            investidores em busca de impacto positivo, imediato, de uma maneira corajosa e
                            criativa. Verá que a Rebel X sabe como navegar no universo de criptoativos,
                            mesmo em períodos de tormenta. Que a grande imprensa segue de olho na Sthorm
                            e na sustentabilidade do projeto Greenbook. E verá o time cada vez mais forte da
                            Sthorm, com executivos do mais alto nível chegando para somar.
                        </p>
                        <br />
                        <p className={styles['texts-july']}>
                            Juntos, estamos construindo um futuro
                            descentralizado para a ciência e a tecnologia.
                        </p>
                        <br />
                        <br />
                        <span>É UMA HONRA TÊ-LO CONOSCO</span>
                    </div>
                    <img src="/images/news/july3/bottomCover1.png" alt="" />
                </section>
                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/title2-pt.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            Enquanto o mundo está cheio de perguntas sobre o momento das criptomoedas, a Rebel X se destaca no cenário como uma empresa especializada em criptoativos, formada por profissionais altamente qualificados e com grande autoridade no universo de blockchain.
                        </p>
                        <p className={styles['texts-july']}>
                            Em momentos de turbulência ou não, a empresa é capaz de fornecer informação precisa, com análises de ponta sobre o mercado e oportunidades de investimento em balcão OTC (over the counter), exclusivas para clientes, para realizar, comprar e negociar criptoativos.
                        </p>
                        <p className={styles['texts-july']}>
                            Constituída em janeiro de 2021, a Rebel X realiza suas operações em rigoroso cumprimento às normas do Banco Central e da Receita Federal.
                            Investir é governar. E, aqui, governa-se com propósito.
                        </p>
                    </div>
                </section>

                <section className={styles.contentWhite}>
                    <img src="/images/news/july3/cover2.png" alt="" />
                    <img src="/images/news/july3/title3-pt.png" alt="" />
                    <div className={styles.textsBlack}>
                        <p className={styles['texts-july']}>
                            Conectar diretamente os artistas ao seu público e criar uma rede de investimento e
                            de impacto positivo em torno da cultura e do entretenimento. Artbit será a
                            plataforma na qual artistas, marcas e pessoas de diversas áreas poderão produzir,
                            consumir e compartilhar conteúdos únicos, enquanto investem na cultura e no
                            entretenimento com todo o impacto positivo que a arte promove.

                        </p>
                        <p className={styles['texts-july']}>
                            A plataforma oferece experiências compartilhadas para cada tipo de usuário e para
                            engajamento em causas de todos os tamanhos, espalhadas pelo mundo inteiro.
                            Nosso objetivo é distribuir recursos entre os artistas, produtores, fãs e espectadores
                            que contribuem para o incentivo da classe artística e de muitas outras causas
                            sociais.
                            A tecnologia está pronta.
                        </p>
                        <p className={styles['texts-july']}>
                            Sua luz e seu grito de talento vão ganhar o nosso planeta.
                        </p>
                    </div>
                    <img src="/images/news/july3/bottomCover2.png" alt="" />
                </section>
                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/title4-pt.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            Uma matéria especial sobre Medicina e inovação destacou a Sthorm e o
                            investimento em medicamentos biológicos, por meio da Mabloc. Em entrevista,
                            Pablo Lobo destacou: “Nosso objetivo é permitir que pessoas comuns invistam em
                            saúde, quebrando o ciclo de dependência das grandes farmacêuticas”.

                        </p>
                        <img src="/images/news/july3/ilustra1.png" alt="" />
                        <a
                            className={styles['buttons-july']}
                            href="https://drive.google.com/file/d/1SFKZJlo80llCoUoicZWAfO4ZLcGiCmHH/view"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            LEIA O ARTIGO
                        </a>
                    </div>
                </section>

                <section className={styles.contentWhite}>
                    <img src="/images/news/july3/cover3.png" alt="" />
                    <img src="/images/news/july3/title5-pt.png" alt="" />
                    <div className={styles.textsBlack}>
                        <p className={styles['texts-july']}>
                            Restabelecer ecossistemas em ambientes economicamente saudáveis.
                        </p>

                        <p className={styles['texts-july']}>
                            Segundo a ONU, será preciso investir oito trilhões de dólares no meio ambiente até
                            2050 para mitigar os efeitos do aquecimento global. Para atingir essa meta,
                            precisamos atrair a iniciativa privada e inovar em resultados.
                        </p>

                        <p className={styles['texts-july']}>
                            Partindo da inovação brasileira CPR-Verde, construímos o Greenbook by Planetary
                            X, uma metodologia e plataforma global de negociação em web3.0 para Ativos
                            Verdes auditáveis, indo muito além do crédito de carbono.
                        </p>

                        <p className={styles['texts-july']}>
                            Por meio do Blended Finance, captaremos recursos do setor privado, visando
                            produzir uma solução escalável que beneficia pequenos produtores, grandes
                            empresas, governo e principalmente o meio ambiente. Transformaremos áreas de
                            preservação em bens rentáveis e em commodities do agronegócio que poderão ser
                            exportadas a países que consomem esses recursos.
                        </p>

                        <p className={styles['texts-july']}>
                            Partindo de 20 mil hectares já mapeados e negociações adiantadas com o governo
                            do estado do Amazonas, o GreenBook by Planetary X já inicia suas operações em
                            ritmo avançado.
                        </p>


                        <p className={styles['texts-july']}>
                            Daqui a dois anos, o projeto prevê solicitar aos Estados Unidos a licença Reg A+,
                            ganhando acesso ao mercado norte-americano para a captação de até 70 milhões de
                            dólares por ano.
                        </p>
                        <p className={styles['texts-july']}>
                            Com esse investimento, vamos desenvolver a plataforma e inventariar 20 mil
                            hectares de reserva que irão gerar uma safra de 16 milhões de ativos de
                            conservação de floresta com receita projetada de até 512 milhões de reais por ano.
                        </p>
                        <p className={styles['texts-july']}>
                            Greenbook by Planetary X é a porta para novos projetos, visionários, ambiciosos,
                            focados na preservação do meio ambiente. Nosso compromisso e nosso apelo,
                            como sempre, têm o olhar para o mundo inteiro.
                        </p>
                        <a
                            className={styles['buttons-july-black']}
                            href="https://planetaryx.io/"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            SAIBA MAIS
                        </a>
                    </div>
                </section>

                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/cover4.png" alt="" />
                    <img src="/images/news/july3/title6-pt.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            Schaefer e Bechara serão Partners e Advisors em todas as operações de impacto da
                            Sthorm.
                        </p>

                        <div className={styles.grid}>
                            <div className={styles['grid-item']}>
                                <img src="/images/news/july3/person1.png" alt="" />
                                <p className={styles['texts-july']}>
                                    Engenheiro Mecânico, pós-graduado em gestão estratégica, Eduardo Schaefer foi
                                    co-fundador da Webra, sendo responsável pela área comercial. Após um buyout
                                    bem-sucedido, tornou-se diretor comercial da Promon e consultor de e-business
                                    para empresas como Itaú, Vale, TAM, entre outras. CEO e Presidente do Conselho
                                    de Administração da ZAP Imóveis por 12 anos, Schaefer tornou-se chefe, em
                                    2018, da área de Negócios Integrados do Grupo Globo. Atualmente é sócio da
                                    Globo Ventures e membro do conselho da Fundação Roberto Marinho, Proz
                                    Educação e SuperBid.
                                </p>
                            </div>
                            <div className={styles['grid-item']}>
                                <img src="/images/news/july3/person2.png" alt="" />
                                <p className={styles['texts-july']}>
                                    Com MBA em Economia e Direito Empresarial especializado em TI e Direito da
                                    Mídia, Marcelo Bechara é ex-assessor jurídico do Ministério da Comunicação,
                                    Procurador Geral e Comissário da Anatel, além de ser ex-membro do Comitê
                                    Gestor da Internet no Brasil - CGI.br, onde ajudou na formulação do Marco Civil
                                    Brasileiro da Internet. Bechara também faz parte do Conselho do IAB – Interactive
                                    Advertising Bureau Brasil – e ocupa a cadeira de Diretor de Relações Institucionais
                                    e Regulamentação da Mídia do Grupo Globo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="/images/news/july3/bottomCover3.png" alt="" />
                </section>
                <section className={styles.footer}>
                    <img className={styles.logo} src="/images/news/july3/logo.png" alt="" />
                    <div className={styles.socialIcons}>
                        <a href="https://www.linkedin.com/company/sthorm/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/icon_linkedin.svg" alt="" />
                        </a>
                        <a href="https://www.instagram.com/sthorm.io/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/icon_instagram.svg" alt="" />
                        </a>
                    </div>
                </section>
            </div>
        </div >
    );
}