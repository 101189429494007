import '../../styles/styles.sass';
import React, { useEffect } from 'react'

export const TextSlider = () => {
    useEffect(() => {
        const bigText = document.querySelector('.text__slider ul');
        const bigTextBis = document.querySelector('.text__slider ul.second');
        // const smallText = document.querySelector('.text-container .small-text');
        // const smallTextBis = document.querySelector('.text-container .small-text.second');
        const counters = {"big":0, "small":0};
        const initial = 0;
        let animation = window.requestAnimationFrame(texteDefile);
        function texteDefile() {
            texteDefileAnimation(bigText, bigTextBis, "left", "big", 1.5);
            // texteDefileAnimation(smallText, smallTextBis, "right", "small", 1.5);
            animation = window.requestAnimationFrame(texteDefile);
            return animation;
        }
    
        function texteDefileAnimation(element, elementBis, direction, counterIndex, speed) {
            if (counters[counterIndex] < (- element.offsetWidth)) {
                counters[counterIndex] = initial;
            } else {
                counters[counterIndex] -= speed;
            }
            element.style[direction] = counters[counterIndex] + "px";
            elementBis.style[direction]  = element.offsetWidth + counters[counterIndex] + "px";
        }
    });


    return (
        <div className="text__slider">
            <ul>
                <li>We embody the spirit of the internet.  The specter that haunts the networks ever since they started merging passionate human souls with wild dream-like machines.</li>
                <li>We embody the spirit of the internet.  The specter that haunts the networks ever since they started merging passionate human souls with wild dream-like machines.</li>
            </ul>
            <ul className="second">
                <li>We embody the spirit of the internet.  The specter that haunts the networks ever since they started merging passionate human souls with wild dream-like machines.</li>
                <li>We embody the spirit of the internet.  The specter that haunts the networks ever since they started merging passionate human souls with wild dream-like machines.</li>
            </ul>
        </div>
    )
}