import '../../styles/styles.sass';
// import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import ShuffleText from 'react-shuffle-text'
import { motion } from 'framer-motion';

export const AboutContent = () => {
    // const ref = useRef();
    const [english, setEnglish] = useState(true); 
    const [espanol, setEspanol] = useState(false); 
    const [portuguese, setPortuguese] = useState(false); 

    //////////////////////// Handling window size & resize ///////////////////////////////////////
    const [isMobile, setMobile] = useState(window.innerWidth < 600);

    const updateMedia = () => {
        setMobile(window.innerWidth < 600);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    useEffect(() => {
        //////////////////////// Numbers list on left margin ///////////////////////////////////////
        const numbersContainer = document.querySelector('.page__numbers__list')

        function createNumbers() {
            var listHeight = numbersContainer.clientHeight; 

            var numbersMax = Math.round(listHeight * 0.1)
            var foo = new Array(numbersMax)
            numbersContainer.innerHTML = ""
            for(var i = 0; i < foo.length; i++){
                numbersContainer.append(('000'+(i + 1)).slice(-3) + "\n"); 
            }
            // console.log(numbersMax)

        }
        createNumbers()

        window.addEventListener('resize', function(event){
            createNumbers();
            
        });

        //////////////////////// Change Pronouncement Language ///////////////////////////////////////
        const switchLanguage = document.querySelectorAll('.switch--language');
        // switchLanguage.forEach(function(language) {
        //     language.addEventListener('click', () => {
        //         setPortuguese(true);
        //     });
        // });
        switchLanguage.forEach(function(language) {
            language.addEventListener('click', () => {
                var constLangId = language.id

                switchLanguage.forEach(function(languageBtn) {
                    if( languageBtn.classList.contains(constLangId) ) {
                        languageBtn.classList.add('active')
                    } else {
                        languageBtn.classList.remove('active')
                    }
                })

                if (constLangId === 'english') {
                    setEnglish(true);
                    setEspanol(false);
                    setPortuguese(false);
                }
                if (constLangId === 'espanol') {
                    setEnglish(false);
                    setEspanol(true);
                    setPortuguese(false);
                }
                if (constLangId === 'portuguese') {
                    setEnglish(false);
                    setEspanol(false);
                    setPortuguese(true);
                }

            });
        });

        //////////////////////// Images when hovering coordinates ///////////////////////////////////////
        if (isMobile === false) {
            const coordTrigger = document.querySelectorAll('.about__coordinates .coordinate');
            const hoverImage = document.querySelectorAll('.coord__img');
            const baseImage = document.querySelector('.base__img');

            coordTrigger.forEach(function(trigger) {
                trigger.onmouseover = function() { 
                    var triggerId = trigger.id;
                    baseImage.style.opacity = 0;
                    hoverImage.forEach(function(hovImg) {
                        if( hovImg.classList.contains(triggerId) ) {
                            hovImg.classList.add('visible')
                        }
                    })
                }
                
                trigger.onmouseout = function() { 
                    baseImage.style.opacity = 1
                    hoverImage.forEach(function(hovImg) {
                        hovImg.classList.remove('visible')
                    })
                }
            });
        }
    }, [isMobile]);
    
    return (
        <>
            <motion.div 
                className="about page__wrapper"
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0, x: '10%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '-10%' }}
            >
                <div className="about__wrapper">
                    <div className="page__numbers__list"></div>

                    <div className="about__content">
                        <div className="about__content__text">
                            <p>Sthorm is a mission-driven special unit, leveraging blockchain technologies for the advancement and dissemination of applied sciences with social and environmental impact.
                                <br/><br/>
                                We create, build and run impact-driven Web3 tools within a distributed, hyper-connected and self-sufficient ecosystem.
                                <br/><br/>
                            </p>
                            <p>
                                Speeding up social and technological enhancement to get to the next level of human potential. Outsmarting the old model of scarcity for the decentralized paradigm of abundance. 
                            </p>
                        </div>

                        <div className="about__content__image">
                            <picture className="base__img">
                                <source media="(min-width: 1921px)" srcSet="/images/about/about_image_2x.png" />
                                <source media="(min-width: 801px) and (max-width: 1920px)" srcSet="/images/about/about_image_2x.png 2x, /images/about/about_image_1x.png 1x" />
                                <source media="(max-width: 800px)" srcSet="/images/about/about_image_sm.png" />
                                <img src="/images/about/about_image_1x.png" alt=""/>
                            </picture>

                            <picture className="coord__img piracicaba">
                                <source media="(min-width: 1921px)" srcSet="/images/about/about_image_piracicaba_2x.png" />
                                <source media="(max-width: 1920px)" srcSet="/images/about/about_image_piracicaba_2x.png 2x, /images/about/about_image_piracicaba_1x.png 1x" />
                                <img src="/images/about/about_image_piracicaba_1x.png" alt=""/>
                            </picture>
                            
                            <picture className="coord__img saopaulo">
                                <source media="(min-width: 1921px)" srcSet="/images/about/about_image_saopaulo_2x.png" />
                                <source media="(max-width: 1920px)" srcSet="/images/about/about_image_saopaulo_2x.png 2x, /images/about/about_image_saopaulo_1x.png 1x" />
                                <img src="/images/about/about_image_saopaulo_1x.png" alt=""/>
                            </picture>

                            <picture className="coord__img buenosaires">
                                <source media="(min-width: 1921px)" srcSet="/images/about/about_image_buenosaires_2x.png" />
                                <source media="(max-width: 1920px)" srcSet="/images/about/about_image_buenosaires_2x.png 2x, /images/about/about_image_buenosaires_1x.png 1x" />
                                <img src="/images/about/about_image_buenosaires_1x.png" alt=""/>
                            </picture>
                        </div>
                        
                        <div className="about__coordinates coordinates">
                            <p className="coordinate" id="piracicaba">
                                <ShuffleText content="Piracicaba" />
                                <ShuffleText content="22.7343° S" />
                                <ShuffleText content="47.6481° W" />
                            </p>

                            <p className="coordinate" id="saopaulo">
                                <ShuffleText content="São Paulo" />
                                <ShuffleText content="23.5558° S" />
                                <ShuffleText content="46.6396° W" />
                            </p>

                            <p className="coordinate" id="buenosaires">
                                <ShuffleText content="Buenos Aires" />
                                <ShuffleText content="34.6037° S" />
                                <ShuffleText content="58.3816° W" />
                            </p>
                        </div>
                    </div>

                    <div className="about__mission">
                        <div className="about__mission__tags">
                            <ShuffleText content="Science" />
                            <ShuffleText content="Technology" />
                            <ShuffleText content="Design" />
                        </div>

                        <div className="about__mission__text">
                            <h1 className="about__title title--display--01">
                                Our mission is based on the weighting of 3 fundamental axes.  
                                <span>People / Planet / Machines</span>
                            </h1>
                        </div>
                    </div>

                    <div className="about__pronouncement" id="Pronouncement">
                        <div className="pronouncement__column">
                            {portuguese === true ? (
                                <h2 className="pronouncement__title title--display--02 font--black lang-module portuguese">Pronunciamiento</h2>
                            ) : (
                                <></>
                            )}
                            {english === true ? (
                                <h2 className="pronouncement__title title--display--02 font--black lang-module english">Pronouncement</h2>
                            ) : (
                                <></>
                            )}
                            {espanol === true ? (
                                <h2 className="pronouncement__title title--display--02 font--black lang-module espanol">Pronunciamiento</h2>
                            ) : (
                                <></>
                            )}
                            
                            <div className="language__switch__wrapper">
                                <button className={english ? 'button button--square underline no-link switch--language active' : 'button button--square underline no-link switch--language'} id="english">EN</button>
                                <button className={espanol ? 'button button--square underline no-link switch--language active' : 'button button--square underline no-link switch--language'} id="espanol">ES</button>
                                <button className={portuguese ? 'button button--square underline no-link switch--language active' : 'button button--square underline no-link switch--language'} id="portuguese">PT</button>
                            </div>

                            {portuguese === true ? (
                                <div className="pronouncement__intro portuguese">
                                    <p>
                                    Os Cypherpunks existem desde setembro de 1992. Naquele tempo, muito foi escrito em criptografia, key escrow's, chip clipper, na internet, a super-estrada da informação, ciberterroristas e cripto anarquia. Nós nos encontramos no centro da tempestade.
                                    <br/><br/>
                                    Tim May, O CYPHERNOMICON
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {english === true ? (
                                <div className="pronouncement__intro english">
                                    <p>
                                        The Cypherpunks have existed since September, 1992. In that time, a vast amount has been written on cryptography, key escrow, Clipper, the Net, the Information Superhighway, cyber terrorists, and crypto anarchy. We have found ourselves (or placed ourselves) at the center of the storm.
                                        <br/><br/>
                                        Tim May, THE CYPHERNOMICON
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {espanol === true ? (
                                <div className="pronouncement__intro espanol">
                                    <p>
                                        Los Cypherpunks han existido desde septiembre de 1992. En ese tiempo, mucho se ha escrito sobre criptografía, custodia de claves, Clipper, la red, la autopista de la información, los ciberterroristas y la criptoanarquía. Nos hemos encontrado (o nos hemos puesto a nosotros mismos) en el centro de la tormenta.
                                        <br/><br/>
                                        Tim May, THE CYPHERNOMICON
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}

                        </div>

                        <div className="pronouncement__column">
                        {portuguese === true ? (
                                <div className="pronouncement__text portuguese">
                                    <p>
                                        Por muito tempo a internet tem sido associada com o ego exagerado de autorretratos vazios de histórias e valores; nada além de vaidades e cobiça daqueles que não conhecem as ferramentas  incríveis que têm nas mãos.<br/>
                                        O coração e o orgulho da WEB estão em outro lugar.
                                    </p>

                                    <p>
                                        A alma que transcende todo esse mal-entendido segue viva com os teimosos que se recusam a vender seus ativos digitais e com aqueles que constroem e melhoram o ecossistema Blockchain de forma anônima, simplesmente porque eles nunca se esqueceram da história.
                                    </p>

                                    <p>
                                        Lembra que a Internet nasceu em 1990 com a ideia brilhante de Tim Berners Lee, um matemático, Físico Teórico que queria espalhar rapidamente pelo mundo as descobertas da ciência. Ele não queria que o conhecimento tivesse proprietários, alguns bilionários donos da informação.
                                    </p>

                                    <p>
                                        Nessas três décadas, sua ideia sofreu duros golpes daqueles mais preocupados com sua imagem e seus bilhões. Mas a alma primeira da Internet não morreu.
                                    </p>

                                    <p>
                                        Nós somos esse espectro idealista, uma guerrilha invencível que assombra as redes e sempre sobreviveu aos repetidos ataques dos protocolos de comunicação.<br/>
                                        Somos como fantasmas, sem rosto, sem logotipo, sem endereço, cintilando em meio aos circuitos, espreitando nas suas falhas.<br/>
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {english === true ? (
                                <div className="pronouncement__text english">
                                    <p>
                                        For too long has the internet been associated with the fleeting egotism of vain self portraits and evanescent stories, endlessly and rapidly circulated by people who do not understand the impressive tools they have at their disposal. But the heart and pride of the web lies elsewhere.
                                    </p>

                                    <p>
                                        The soul that transcends all that heedless impermanence lies deep beneath the surface, with the stubborn early adopters that year after year refuse to let go of their digital assets, regardless of the ever changing circumstances. It lies with the selfless contributors anonymously building and improving blockchain ecosystems.
                                    </p>

                                    <p>
                                        We are the spirit of the internet. A specter that haunts the networks ever since they started merging passionate human souls with wild dream-like machines. A phantom that all the powers in our ever-growing carapace of devices and communication protocols have always failed to exorcise. The ghost of ultimate distribution.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {espanol === true ? (
                                <div className="pronouncement__text espanol">
                                    <p>
                                        Hace demasiado tiempo ya que Internet se asocia al egoísmo fugaz de los autorretratos vanos y a historias evanescentes, rápida e interminablemente circuladas por personas que no entienden las extraordinarias herramientas que tienen a su disposición. Pero el corazón y el orgullo de la red, están en otra parte.
                                    </p>

                                    <p>
                                        El alma que trasciende toda esa impermanencia despreocupada se encuentra lejos de la superficie, entre los pioneros obstinados que año tras año se rehúsan a desprenderse de sus activos digitales, sin importar las circunstancias. Se encuentra allí donde voluntarios desinteresados construyen y optimizan anónimamente los ecosistemas de la blockchain.
                                    </p>

                                    <p>
                                        Somos ese espíritu de la Internet. Una sombra que acecha las redes desde que comenzaron a fusionar almas humanas apasionadas con salvajes máquinas de ensueño. Un fantasma que todos los poderes de nuestro siempre creciente armazón de dispositivos y protocolos de comunicación, nunca han logrado exorcizar. El espectro de la distribución absoluta.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="pronouncement__column">
                            {portuguese === true ? (
                                <div className="pronouncement__text portuguese">                               
                                    <p>
                                        Tampouco desejamos a atenção sem compromisso, pouco importa quanto dinheiro isso pode nos trazer.<br/>
                                        É MAIS DO QUE UMA ESCOLHA ESTÉTICA. É UMA ESCOLHA MORAL.
                                    </p>

                                    <p>
                                        Os riscos envolvidos são tão urgentes e os ganhos para o mundo todo tão extraordinários que nenhum tempo pode ser desperdiçado em promoções pessoais. Aqui não há espaço para o ego gigante de ninguém.<br/>
                                        Basta dizer que todos buscamos a distribuição do conhecimento e da riqueza de forma coletiva e anônima. A internet nasceu com essa ideia, lembra?
                                    </p>

                                    <p>
                                        A descentralização das finanças e das ciências, longe das poucas mãos que as controlam hoje, nunca será alcançada pelo egoísmo daqueles mais preocupados com a imagem que projetam do que com o impacto global das suas ações.
                                    </p>

                                    <p>
                                        Até há pouco tempo, sabíamos que nossos objetivos eram inatingíveis … não porque a rede  já não tivesse o potencial de descentralização, mas porque ela estava preocupada demais com a mera comunicação de dados, ao invés de pensar no valor da informação.
                                    </p>

                                    <p>
                                        O cenário hoje é totalmente diferente. As Distributed Ledgers Technologies como a Blockchain mudaram isso para sempre.<br/>
                                        Esses instrumentos têm um poder colossal para disseminar inovação e prosperidade em uma escala mundial sem precedentes.
                                    </p>

                                    <p>
                                        A internet não é propriedade de ninguém e não deve estar centrada nas mãos de alguns que ainda acreditam ser os donos das letras dáblio dáblio dáblio.<br/>
                                        Faz trinta anos ela surgiu como uma alavanca poderosa da ciência para a evolução de toda a humanidade, sem privilégios para ninguém.
                                    </p>

                                    <p>
                                        Nós acreditamos nisso. Trabalhamos para isso.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {english === true ? (
                                <div className="pronouncement__text english">
                                    <p>
                                        Spirits, specters, phantoms and ghosts alike, all do without a recognizable face or a determinate location. Forever flickering through the circuits and lurking in the glitches, we have no use for a specific logo or a distinguishing typeface. Nor do we crave uncommitted attention no matter how monetizable it might be.
                                    </p>

                                    <p>
                                        It is a moral choice, as much as an aesthetic one: The tasks at hand are so urgent and difficult, so many the risks involved, that no time can be wasted in branding or promotion, and let alone formal, personal introductions. Suffice it to say we all collectively and anonymously seek the distribution of knowledge and of wealth.
                                    </p>

                                    <p>
                                        The decentralization of finance and science, away from the hands of the few in which they are concentrated today, will never be achieved by self-centered, selfish individuals, more preoccupied with the image they project than with the impact of their actions.
                                    </p>

                                    <p>
                                        We understand now that during the previous iterations of the world wide web, our goals were yet unattainable. Not because the net lacked the potential for decentralization that it has always retained, but because it was concerned with mere communication or data, rather than actual value and things.
                                    </p>

                                    <p>
                                        Distributed ledgers and blockchain technologies have changed that, for good. Let this be the time when we draw upon these instruments of colossal and shattering power in order to disseminate innovation and prosperity in an unprecedented scale.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                            {espanol === true ? (
                                <div className="pronouncement__text espanol">
                                    <p>
                                        Los espíritus, las sobras, los fantasmas y los espectros prescinden todos por igual de rostros reconocibles o ubicaciones determinadas. Siempre al acecho, en los fallos,  y centelleando a través de los circuitos, nosotros no necesitamos ni un logo que nos caracterice, ni un tipo de letra que nos distinga. Tampoco codiciamos atención sin compromiso, no importa cuán lucrativa pueda resultar.
                                        </p>

                                    <p>
                                        Es una elección tanto moral, como estética: Las tareas que tenemos por delante son tan urgentes y difíciles, tantos los riesgos involucrados, que no hay tiempo para perder en la promoción de una marca, o en la presentación formal de las personas involucradas. Basta decir que todos nosotros, colectiva y anónimamente, buscamos la distribución del conocimiento y de la riqueza.
                                    </p>

                                    <p>
                                        La descentralización de las finanzas y la ciencia, lejos de las manos de esos pocos que hoy las concentran, no pueden lograrla individuos egoístas, centrados en sí mismos y más preocupados por la imagen que proyectan que por el impacto de sus acciones.
                                    </p>

                                    <p>
                                        Comprendemos ahora que durante las iteraciones anteriores de Internet, nuestros objetivos eran aún inalcanzables. No porque la red careciera del potencial de descentralización que siempre ha retenido, sino porque estaba limitada a la mera comunicación de datos, y no era todavía una Internet del valor y de las cosas.
                                    </p>

                                    <p>
                                        La tecnología de registros distribuidos y la blockchain han cambiado todo definitivamente y para bien. Ha llegado el momento de recurrir al poder colosal y arrasador de estos instrumentos para diseminar la innovación y la prosperidad en una escala sin precedentes.
                                    </p>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="pronouncement__footer">
                            {portuguese === true ? (
                                <h5 className="title--display--02">Junte-se a nós a ajude-nos a encontrar os outro</h5>
                            ) : (
                                <h5 className="title--display--02">Join us, and help us find the others.</h5>
                            )}
                        </div>

                    </div>

                </div>
            </motion.div>
        </>
    )
}