import '../../styles/styles.sass';
import React, { useEffect } from 'react'

import ShuffleText from 'react-shuffle-text'

export const Preloader = () => {
    useEffect(() => {
        const preloaderBox = document.querySelector('.preloader__box');

        setTimeout(() => {
            preloaderBox.classList.add('loaded');
        }, 6000)
    }, []);

    return (
        <div className="preloader">
            <div className="preloader__box">
                <div className="preloader__header">
                    <div className="preloader__logo">
                        <img src="/images/sthorm/sthorm_logo.svg" alt="Sthorm Logo" />
                    </div>
                    <p>LatAm roots / Internet Souls</p>
                </div>

                <div className="preloader__progress"></div>

                <div className="preloader__coordinates">
                    <ShuffleText content="001 23.5558° S, 46.6396° W" />
                    <ShuffleText content="002 22.7343° S, 47.6481° W" />
                    <ShuffleText content="003 34.6037° S, 58.3816° W" />
                </div>
            </div>
        </div>
    )
}