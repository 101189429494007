import ShuffleText from 'react-shuffle-text'

export const Coordinates = () => {
    return (
        <>
            <p className="coordinate">
                <ShuffleText content="001" />
                <ShuffleText content="23.5558° S, 46.6396° W" />
                <ShuffleText content="Piracicaba" />
            </p>
            <p className="coordinate">
                <ShuffleText content="002" />
                <ShuffleText content="22.7343° S, 47.6481° W" />
                <ShuffleText content="São Paulo" />
            </p>
            <p className="coordinate">
                <ShuffleText content="003" />
                <ShuffleText content="34.6037° S, 58.3816° W" />
                <ShuffleText content="Buenos Aires" />
            </p>
        </>
    )
}