import { AnimatePresence } from 'framer-motion';

import { Outlet } from "react-router-dom"

export default function AnimatedRoutes() {

    return (
        <AnimatePresence>
            <Outlet />   
        </AnimatePresence>
    )
}