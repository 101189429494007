import '../../../styles/styles.sass';
import React from 'react'
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

// import { FormContact } from "../../forms/FormContact";
// import { SocialMedia } from "../../../components/SocialMedia";

export const Footer = () => {
    const body = document.body;
    const lightTheme = "light";
    const darkTheme = "dark";

    let theme;

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { 
        theme = "dark"
    } else {
        theme = "light"
    }

    const switchTheme = (e) => {
        if (theme === darkTheme) {
            body.classList.replace('dark-theme', 'light-theme');
            localStorage.setItem("theme", "light");
            theme = lightTheme;
        } else {
            body.classList.replace('light-theme', 'dark-theme');
            localStorage.setItem("theme", "dark");
            theme = darkTheme;
        }
    };

    return (
        <footer className="footer">
            {/* Modal Contact */}
            {/* {contact &&
                <FormContact contact={true} />
            } */}

            {/* <SocialMedia /> */}

            <div className="footer__copyright">
                <span>All rights reserved 2022</span>
            </div>

            <div className="footer__navigation">
                {/* <button className={contact ? 'button button__contact underline active': 'button button__contact underline'} id="trigger-contact" onClick={()=> setContact(!contact)}>
                    Contact
                </button> */}
                <button className="button button__contact underline">
                    <a href="mailto:people@sthorm.io">Contact</a>
                </button>
                <Link className="button button--link underline anchor-link" to={{pathname: '/about', hash: 'Pronouncement'}}>Find the Others</Link>
                {/* <button className={isDark ? 'button button--switch-mode active': 'button button--switch-mode'} id="btn-dark-mode" aria-label="Switch Mode" onClick={()=> setIsDark(!isDark)}></button> */}
                <button 
                    className={theme === "dark" ? 'button button--switch-mode active': 'button button--switch-mode'}
                    id="btn-dark-mode" 
                    aria-label="Switch Mode" 
                    onClick={(e) => switchTheme(e)}
                ></button>
            </div>

        </footer>
    )
}