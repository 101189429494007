import '../../../styles/styles.sass';
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

import { TextSlider } from "../../TextSlider";
// import { SocialMedia } from "../../../components/SocialMedia";

export const Header = () => {
    //////////////////////// Show/hide projects menu ///////////////////////////////////////
    const [isOpen, setIsOpen] = useState(); 

    useEffect(() => {
        const header = document.querySelector('.header');
        const linkMenu = document.querySelectorAll('.navigation__link');
        const itemMenu = document.querySelectorAll('.header__navigation__item');
        const projectsList = document.querySelector('.header__navigation__projects__list');

        function toggleProjects() {
            if (header.classList.contains('active')) {
                projectsList.style.transition = 'all 0.6s ease-out';
                projectsList.style.opacity = 0;
                projectsList.style.transform = 'translateY(-105%)';
                setTimeout(() => {
                    projectsList.classList.remove('active');
                    header.classList.remove('active');
                    
                    setIsOpen(false);
                }, 600)
            } else {
                header.classList.add('active');
                setIsOpen(true);

                setTimeout(() => {
                    projectsList.classList.add('active');
                    projectsList.style.transition = 'all 0.6s ease-out';
                    projectsList.style.transform = 'translateY(0)';
                    projectsList.style.opacity = 1;
                }, 600)
            }
            
            var index = 0; 
            var interval = setInterval(function() { 
                if (header.classList.contains("active")) {
                    itemMenu[index++].classList.add("active");
                    if (index === itemMenu.length) { 
                        clearInterval(interval); 
                    }
                } else {
                    itemMenu[index++].classList.remove("active");
                    if (index === itemMenu.length) { 
                        clearInterval(interval); 
                    }
                }
            }, 100);
        }
        document.querySelector('#projects-trigger').addEventListener('click', () => {
            toggleProjects();
        });

        function toggleMenu() {
            if (header.classList.contains('active')) {
                projectsList.style.transition = 'all 0.6s ease-out';
                projectsList.style.opacity = 0;
                projectsList.style.transform = 'translateY(-105%)';
                projectsList.classList.remove('active');
                header.classList.remove('active');
            } else {
                header.classList.add('active');
                projectsList.classList.add('active');
                projectsList.style.transition = 'all 0.6s ease-out';
                projectsList.style.transform = 'translateY(0)';
                projectsList.style.opacity = 1;
            }
        }

        document.querySelector('#menu-trigger').addEventListener('click', () => {
            toggleMenu();
        });
    
        linkMenu.forEach(function(link) {
            link.addEventListener('click', () => {
                if (header.classList.contains('active')) {
                    toggleMenu();
                    setIsOpen(false);
                }
                linkMenu.forEach(function(link) {
                    link.classList.remove('active');
                })
                link.classList.add('active');
            });
        });
    
    }, []);
    

    return (
        <header className="header inactive">
            <div className="header__navigation__content">
                <div className="header__logo">
                    <Link className="navigation__link" to={'/'}>
                        <img src="/images/sthorm/sthorm_logo.svg" alt="Sthorm Logo" />
                    </Link>
                </div> 

                <nav className="header__navigation">
                    <ul className="header__navigation__list">
                        <li className="header__navigation__item">
                            <Link className="navigation__link" to={'/about'}>About</Link>
                        </li>
                        <li className={isOpen ? 'header__navigation__item button button--primary button--square no-link active' : 'header__navigation__item button button--primary button--square no-link'} id="projects-trigger" onClick={()=> setIsOpen(!isOpen)}>
                            Projects
                        </li>
                        <li className="header__navigation__item">
                            <a className="button button--link underline" href="https://pickle-lace-a6c.notion.site/Work-with-us-Sthorm-6ceef06e897d41488ef673a471f6d4e5" target="_blank" rel="noopener noreferrer">Work with us</a>
                        </li>
                    </ul>
                    {/* <div className="follow"></div> */}
                </nav>

                <div className="header__navigation__projects__list">
                    <ul>
                        <li className="projects__list__item projects__list__item--artbit"><a href="https://artbit.com/" target="_blank" rel="noopener noreferrer">Artbit</a></li>
                        <li className="projects__list__item projects__list__item--cryme"><a href="https://cryme.io/" target="_blank" rel="noopener noreferrer">Cry.me</a></li>
                        <li className="projects__list__item projects__list__item--goodnoise"><a href="https://goodnoise.io/" target="_blank" rel="noopener noreferrer">Goodnoise</a></li>
                        <li className="projects__list__item projects__list__item--greenbook"><a href="https://greenbook.fi/" target="_blank" rel="noopener noreferrer">Greenbook</a></li>
                        <li className="projects__list__item projects__list__item--immunox"><a href="https://immunox.io/" target="_blank" rel="noopener noreferrer">ImmunoX</a></li>
                        <li className="projects__list__item projects__list__item--mabloc"><a href="https://mabloc.com/" target="_blank" rel="noopener noreferrer">Mabloc</a></li>
                        <li className="projects__list__item projects__list__item--multiversety"><a href="https://multiversety.com/" target="_blank" rel="noopener noreferrer">Multiversety</a></li>
                        <li className="projects__list__item projects__list__item--paradox"><a href="https://paradox.one/" target="_blank" rel="noopener noreferrer">Paradox1</a></li>
                        <li className="projects__list__item projects__list__item--planetaryx"><a href="https://planetaryx.io/" target="_blank" rel="noopener noreferrer">Planetary X</a></li>
                        <li className="projects__list__item projects__list__item--rebelx"><a href="https://www.rebelx.io/" target="_blank" rel="noopener noreferrer">RebelX</a></li>
                        <li className="projects__list__item projects__list__item--theos"><a href="https://theos.fi/" target="_blank" rel="noopener noreferrer">Theos</a></li>
                        <li className="projects__list__item projects__list__item--viralcure"><a href="https://viralcure.org/" target="_blank" rel="noopener noreferrer">ViralCure</a></li>
                    </ul>
                </div>
            </div>

            <div className="header__text__slider__wrapper">
                <TextSlider />
            </div>

            <button className={isOpen ? 'header__hamburger button button--icon no-link active' : 'header__hamburger button button--icon no-link'} id="menu-trigger" aria-label="Open menu" onClick={()=> setIsOpen(!isOpen)}>
                <span className="hamburger__bar"></span>
                <span className="hamburger__bar"></span>
            </button>

        </header>
    )
}