import { Header } from "../../components/layout/Header"
import { Footer } from "../../components/layout/Footer"

import AnimatedRoutes from '../../utils/AnimatedRoutes'

export default function GeneralContent () {
  
  return (
    <>
      <Header />
      <AnimatedRoutes />
      <Footer />
    </>
  )
}