import styles from "./styles.module.scss";

export function July2Page() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <section className={styles.welcome}>
                    <img src="/images/news/july2/hero.png" alt="" />
                    <img src="/images/news/july2/title-d-verse.png" alt="" />
                    <div className={styles.textsTitle}>
                        <p className={styles['texts-july']}>
                            Na plataforma de comunicação da Sthorm, você verá que o ViralCure
                            está em contagem regressiva para se tornar global. Que uma nova
                            biotech está nascendo, com foco na cura do câncer. Saberá que nem
                            o céu é o limite para Artemis. Nem para Ringo Starr. E conhecerá o
                            novo hub da Sthorm, já em construção em Piracicaba.
                        </p>
                        <br />
                        <p className={styles['texts-july']}>
                            Juntos, estamos construindo um futuro descentralizado para a
                            ciência e tecnologia.
                        </p>
                        <br />
                        <br />
                        <span>É UMA HONRA TÊ-LO CONOSCO</span>
                        <img src="/images/news/july2/footer-welcome" alt="" />
                    </div>
                </section>
                <section className={styles.viralcureCount}>
                    <img src="/images/news/july2/viralcure-count.png" alt="" />
                    <div className={styles.viralcureCountTexts}>
                        <p className={styles['texts-july']}>
                            O mês de setembro marcará uma nova e mais ambiciosa etapa do
                            ViralCure. Em um grande evento de lançamento, a plataforma
                            ultrapassará as fronteiras nacionais para se tornar global. Depois
                            de ajudar o Hospital das Clínicas, maior complexo hospitalar da
                            América Latina, a enfrentar a pandemia, arrecadando mais de R$ 33
                            milhões, a plataforma avança em sua missão de libertar a ciência e
                            os cientistas, reféns dos bancos e fundos de investimento
                            internacionais.
                        </p>
                        <p className={styles['texts-july']}>
                            Além da forte atuação e comprometimento com as missões do HCFMUSP,
                            o ViralCure se tornará uma ferramenta completa de financiamento
                            alternativo à disposição da ciência por meio de tecnologias
                            alinhadas à filosofia da Web3.0.
                        </p>
                        <p className={styles['texts-july']}>
                            Nesta nova fase, o ecossistema ViralCure buscará ser um
                            instrumento de conscientização à serviço da saúde mundial,
                            utilizando a tecnologia DeFi ao lado de métodos DLT (Distributed
                            Ledger Technologies) para estimular a pesquisa e o desenvolvimento
                            científico.
                        </p>
                        <a className={styles['buttons-july']}
                            href="https://viralcure.org/?utm_source=disparo-unico&utm_medium=email&utm_campaign=contagem-regressiva-viral-cure-pt-br-19-07-2022"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            CONFIRA O SITE
                        </a>
                    </div>
                </section>

                <section className={styles.imunoX}>
                    <img src="/images/news/july2/imuno-x-top.png" alt="" />
                    <img src="/images/news/july2/imuno-x-title.png" alt="" />

                    <p className={styles['texts-july']}>
                        A mais nova e sofisticada biotech de imunoterapia contra o câncer
                        acaba de ser inaugurada em parceria com a Sthorm. Entre seus
                        cofundadores – um time de cientistas de altíssimo nível – está o
                        professor titular da FMUSP e presidente da Fundação Pró-Sangue, Dr.
                        Vanderson Rocha.
                    </p>

                    <p className={styles['texts-july']}>
                        Os cientistas já contam com um portfólio bastante promissor no
                        desenvolvimento de três linhas de produto:
                    </p>
                    <div className={styles.textsGrid}>
                        <p className={styles['texts-july']}>
                            CAR-T, vacina de RNA e, em especial, uma vacina de células
                            dendríticas. Essa vacina, quando aplicada em pacientes, ativa o
                            sistema imune na luta contra o câncer e potencializa outras
                            terapias aplicadas em conjunto.
                        </p>
                        <p className={styles['texts-july']}>
                            Extremamente importante é dizer que essa vacina já passou por
                            estudos clínicos de fase II, com resultados muito animadores que
                            justificam o início da terceira e última fase de estudos.
                        </p>
                    </div>
                    <p className={styles['texts-july']}>
                        Nesta semana, contratamos o primeiro membro da equipe técnica, a
                        cientista Dra. Patrícia Cruz, com mais de 35 anos de experiências
                        aplicadas à pesquisa em imunologia e 20 anos especificamente
                        dedicados à manipulação de células dendríticas.
                    </p>

                    <p className={styles['texts-july']}>
                        A cura do câncer é o maior desafio da Medicina nas últimas décadas.
                        É tempo de respondermos a esse chamado.
                    </p>
                    <img src="/images/news/july2/imuno-x-title-science.png" alt="" />
                    <div className={styles.scientist}>
                        <img src="/images/news/july2/patricia.png" alt="" />
                        <p className={styles['texts-july']}>Patrícia Cruz Bergami-Santos, Ph.D.</p>
                    </div>

                    <p className={styles['texts-july']}>
                        Dentro do complexo da ImmunoX, Patrícia é líder, em tempo integral,
                        no desenvolvimento de vacinas contra o glioblastoma. Com
                        Pós-Doutorado Sênior em imunologia, ela tem mais de 30 anos de
                        experiência em pesquisa científica, sendo 20 anos dedicados
                        totalmente ao desenvolvimento de terapias baseadas em células
                        dendríticas.
                    </p>
                </section>

                <section className={styles.findTheOthers}>
                    <img src="/images/news/july2/find-the-others.png" alt="" />
                    <img src="/images/news/july2/sthorm-news.png" alt="" />
                    <p className={styles['texts-july']}>
                        Recentemente, Sthorm e a Mabloc foram notícia em diversas mídias
                        nacionais e internacionais, com reportagens sobre a nova gama de
                        ferramentas de financiamento alternativo à disposição da ciência -
                        incluindo os importantes avanços da Mabloc para o desenvolvimento de
                        um potente anticorpo monoclonal contra a Covid-19.
                    </p>

                    <div>
                        <p className={styles['texts-july']}>
                            Confira a reportagem no COINTELEGRAPH, uma das principais vozes do
                            setor, que destacou a notícia em suas versões em português e
                            inglês.
                        </p>
                        <a
                            className={styles['buttons-july']}
                            href="https://cointelegraph.com.br/news/blockchain-helps-company-raise-brl-50-million-to-create-treatment-against-covid"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            LEIA O ARTIGO
                        </a>
                    </div>
                </section>

                <section className={styles.artemis}>
                    <img src="/images/news/july2/artemis-hero.png" alt="" />
                    <img src="/images/news/july2/artemis-title.png" alt="" />
                    <div className={styles.artemisTexts}>
                        <p className={styles['texts-july']}>Artemis – NFTs em órbita na Estação Espacial Internacional</p>
                        <p className={styles['texts-july']}>
                            Com um projeto pioneiro em parceria com a NASA, a Artemis Music
                            realizou há um ano a primeira viagem espacial de uma música e arte
                            em NFT. Enviadas por ondas de rádio, essas obras orbitaram a Terra
                            a bordo da Estação Espacial Internacional, que flutua a 400 Km de
                            altitude e move-se a 28 mil quilômetros por hora.
                        </p>
                        <p className={styles['texts-july']}>
                            Além da recente colaboração na ativação Starr's Song, a Sthorm
                            oferece à Artemis um sistema de AirDrops de NFTs que contemplam
                            dois contratos inteligentes: ERC721 e ERC155. O primeiro
                            representa o padrão de tokens no Ethereum para NFT, servindo de
                            alicerce para bilhões de dólares em tokens não fungíveis. O
                            segundo simboliza um novo padrão que facilita a criação de tokens
                            exclusivos e itens digitais – iniciativa que pode beneficiar
                            empresas do ramo de games em blockchain. Entre suas
                            funcionalidades, o sistema da Sthorm permite a criação de lotes e
                            agendamento de “mintagens” ou transferências de NFTs.
                        </p>

                        <div>
                            <p className={styles['texts-july']}>
                                Paralelamente, o time de desenvolvimento trabalha na criação de
                                um novo contrato inteligente atualizável que suportará o padrão
                                ERC2981, permitindo receber pagamento de royalties para NFTs. Ao
                                mesmo tempo, a equipe atua na modificação do sistema de GPS para
                                uma Whitelabel.
                            </p>
                            <p className={styles['texts-july']}>
                                A área de desenvolvimento também comemora uma realização muito
                                importante nos últimos dias: uma alteração de permissibilidade
                                em smart contracts, utilizando uma aplicação que adiciona mais
                                de uma carteira como dona de um contrato específico.
                            </p>
                        </div>
                    </div>
                    <img src="/images/news/july2/artemis-title-footer.png" alt="" />
                    <img src="/images/news/july2/artemis-footer-hero.png" alt="" />
                </section>

                <section className={styles.paradox}>
                    <img src="/images/news/july2/paradox-title.png" alt="" />
                    <div className={styles.textsParadox}>
                        <p className={styles['texts-july']}>
                            Aniversário de Ringo Starr em colaboração com a Sthorm viajando na
                            Estação Espacial
                        </p>
                        <p className={styles['texts-july']}>
                            No aniversário de 82 anos de Ringo Starr, dia 7 de julho, sua
                            Starrsong e sua canção Let’s change the world orbitaram o planeta
                            na estação espacial da NASA. Uma colaboração da STHORM e da NASA
                            ARTEMIS. Veja mais no link:
                        </p>

                        <a
                            className={styles['buttons-july']}
                            href="https://ringostarrsong.paradox.one/?utm_source=disparo-unico&utm_medium=email&utm_campaign=contagem-regressiva-viral-cure-pt-br-19-07-2022"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            VEJA MAIS
                        </a>
                    </div>
                </section>

                <section className={styles.sthorm}>
                    <img src="/images/news/july2/sthorm-title.png" alt="" />
                    <div>
                        <p className={styles['texts-july']}>
                            Em Piracicaba (SP), Sthorm constrói o primeiro Hub de Blockchain
                            do país
                        </p>
                        <p className={styles['texts-july']}>
                            Como parte do ecossistema de impacto que estamos edificando, um
                            novo espaço de cultura, produção e aprendizado se integra à
                            comunidade Sthorm. Com 400 mil m², a área da antiga Usina Monte
                            Alegre, em Piracicaba, SP, tem em seu DNA histórico a inovação e o
                            empreendedorismo. Ali foram construídas as primeiras locomotivas
                            100% brasileiras e a primeira fábrica de papel do mundo a usar o
                            bagaço de cana-de-açúcar como matéria-prima. Em seu auge, foi a
                            segunda maior usina açucareira do país.
                        </p>

                        <p className={styles['texts-july']}>
                            Nas ruínas de uma usina que escreveu parte de nossa história
                            agrícola será instalado um grande núcleo de inteligência e
                            entusiasmo, dedicado à ciência e tecnologia. Junto ao ecossistema
                            da Sthorm, o hub será um espaço para empreendedores, escritórios
                            comerciais, restaurantes, auditórios, salas de reunião, espaços de
                            coworking, áreas de experimentação de tecnologias e espaços para
                            eventos.
                        </p>
                    </div>
                    <img src="/images/news/july2/sthorm-footer-title.png" alt="" />
                    <img src="/images/news/july2/sthorm-footer-title-bottom.png" alt="" />

                </section>

                <div className={styles.socialIcons}>
                    <a href="https://www.linkedin.com/company/sthorm/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/icon_linkedin.svg" alt="" />
                    </a>
                    <a href="https://www.instagram.com/sthorm.io/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/icons/icon_instagram.svg" alt="" />
                    </a>
                </div>

            </div>
        </div>
    );
}
