import { Route, Routes, useLocation } from "react-router-dom";

import HomeContent from "../views/HomeContent";
import { AboutContent } from "../views/AboutContent";
import { July1Page } from "../views/News/July1";
import { July2Page } from "../views/News/July2";
import { August1PageEN } from "../views/News/August1/en";
import { August1PagePT } from "../views/News/August1/pt";
import GeneralContent from "../views/GeneralContent/GeneralContent";

export default function Routing() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route exact path="/" element={<GeneralContent />}>
        <Route exact path="/" element={<HomeContent />} />
        <Route path="/about" element={<AboutContent />} />
        <Route path="/news/july-1" element={<July1Page />} />
        <Route path="/news/july-2" element={<July2Page />} />
        <Route path="/news/en/august-1" element={<August1PageEN />} />
        <Route path="/news/pt/august-1" element={<August1PagePT />} />
      </Route>
    </Routes>
  );
}
