import styles from './styles.module.scss';

export function August1PageEN() {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <section className={styles.welcome}>
                    <img src="/images/news/july3/cover1.png" alt="" />
                    <img src="/images/news/july3/title1-en.png" alt="" />
                    <div className={styles.textsTitle}>
                        <p className={styles['texts-july']}>
                            In this issue, you’ll discover how Sthorm will connect artists, audiences, and investors in search of brave, creative, and immediate positive impact. You’ll learn that Rebel X can navigate the crypto world even in the stormiest weather. You’ll see that the mainstream press keeps watching Sthorm and its Greenbook project to track its phenomenal strides in sustainability efforts. And you’ll witness the growing strength of Sthorm, learning about the latest top-ranking executives who have joined the team to add their expertise to the cause.
                        </p>
                        <br />
                        <p className={styles['texts-july']}>
                            Together we’re building a decentralized future
                            for science and technology.
                        </p>
                        <br />
                        <br />
                        <span>IT’S AN HONOR TO HAVE YOU WITH US.</span>
                    </div>
                    <img src="/images/news/july3/bottomCover1.png" alt="" />
                </section>
                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/title2-en.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            While the cryptocurrency world is currently awash in questions, Rebel X, formed by highly qualified professionals recognized for their authority in the blockchain universe, rises above the pack. In chaotic and calm times alike, the company specializing in cryptoassets provides accurate information defined by cutting-edge market analysis while offering exclusive OTC (over the counter) investment opportunities.
                        </p>
                        <p className={styles['texts-july']}>
                            Established in January 2021, Rebel X operates in strict compliance with the rules of the Brazilian Central Bank and the Federal Revenue Service. Investing is governing. And here, you govern with purpose.
                        </p>
                    </div>
                </section>

                <section className={styles.contentWhite}>
                    <img src="/images/news/july3/cover2.png" alt="" />
                    <img src="/images/news/july3/title3-en.png" alt="" />
                    <div className={styles.textsBlack}>
                        <p className={styles['texts-july']}>
                            Connect artists directly to their audiences and create a positive impact investment network built around culture and entertainment. Artbit will be recognized as the platform where artists, brands and people from different interests and areas produce, consume, and share unique content—all while investing in culture to benefit from the endless positive potential impact that art can generate.
                        </p>
                        <p className={styles['texts-july']}>
                            The platform promotes shared experiences for every type of user, while fostering engagement in initiatives of all sizes, originating in every corner of the world. Our aim? To distribute the revenue among artists, producers, fans, and spectators who contribute and encourage the arts as well as powering the progress of many other social causes.
                        </p>
                        <p className={styles['texts-july']}>
                            The technology is ready. Your light and the resounding battle cry of your talent will conquer our planet.
                        </p>
                    </div>
                    <img src="/images/news/july3/bottomCover2.png" alt="" />
                </section>
                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/title4-en.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            A feature article on medicine and innovation highlighted Sthorm and our investment in biological treatments through Mabloc. Pablo Lobo explained the mission: “Our goal is to allow ordinary people to invest in health and break the cycle of dependence on big pharmaceutical companies.”
                        </p>
                        <img src="/images/news/july3/ilustra1.png" alt="" />
                        <a
                            className={styles['buttons-july']}
                            href="https://drive.google.com/file/d/1SFKZJlo80llCoUoicZWAfO4ZLcGiCmHH/view"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            READ ARTICLE
                        </a>
                    </div>
                </section>

                <section className={styles.contentWhite}>
                    <img src="/images/news/july3/cover3.png" alt="" />
                    <img src="/images/news/july3/title5-en.png" alt="" />
                    <div className={styles.textsBlack}>
                        <p className={styles['texts-july']}>
                            Restoring ecosystems with economically healthy environments.
                        </p>

                        <p className={styles['texts-july']}>
                            According to the UN, $8 trillion dollars must be invested in the environment by 2050 to mitigate the effects of global warming. To achieve this lofty and pressing goal, we need to attract funding and innovation from the private sector.
                        </p>

                        <p className={styles['texts-july']}>
                            Based on the enterprising Brazilian CPR-Verde (the Green Rural Product Certificate, made for rural agribusiness producers to raise funds that conserve native forests and their ecosystems), we built the Greenbook by Planetary X, a global Web 3.0 trading methodology and platform for auditable green assets that move far beyond simply issuing carbon credits.
                        </p>

                        <p className={styles['texts-july']}>
                            Through Blended Finance, we will raise funds from the private sector to produce a scalable solution benefiting small producers, large companies, the government, and, most importantly, the environment. Our vision will enable conservation areas to be transformed into profitable goods and agribusiness commodities to be exported to markets that consume these resources.
                        </p>

                        <p className={styles['texts-july']}>
                            Starting with 20,000 hectares already mapped and negotiated in advance with the Brazilian state government of Amazonas, the Greenbook by Planetary X has already launched its operations—and at a rapid pace.
                        </p>


                        <p className={styles['texts-july']}>
                            In two years, the project intends to apply to the United States for a Reg A+ license, gaining access to the North American market in order to raise as much as $70 million each year.
                        </p>
                        <p className={styles['texts-july']}>
                            With these future investments, we will develop a platform to inventory 20,000 hectares of preserved areas primed to generate a crop of 16 million forest conservation assets representing projected annual revenues of up to R$512 million Brazilian reais.
                        </p>
                        <p className={styles['texts-july']}>
                            Greenbook by Planetary X is an avenue to new, visionary and ambitious projects that preserve the environment. Our ongoing commitment and our proposal, as always, reminds everyone to look to the whole world for solutions.
                        </p>
                        <a
                            className={styles['buttons-july-black']}
                            href="https://planetaryx.io/"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            LEARN MORE
                        </a>
                    </div>
                </section>

                <section className={styles.contentBlack}>
                    <img src="/images/news/july3/cover4.png" alt="" />
                    <img src="/images/news/july3/title6-en.png" alt="" />
                    <div className={styles.textsWhite}>
                        <p className={styles['texts-july']}>
                            Schaefer and Bechara will serve as partners and advisers on all of Sthorm’s impact operations.
                        </p>

                        <div className={styles.grid}>
                            <div className={styles['grid-item']}>
                                <img src="/images/news/july3/person1.png" alt="" />
                                <p className={styles['texts-july']}>
                                    A mechanical engineer with a postgraduate degree in Strategic Management, Eduardo Schaefer co-founded Webra and oversaw its commercial interests. After navigating a successful buyout, he pivoted to become commercial director at Promon and an e-business consultant for leading Brazilian companies such as Itaú, Vale, and TAM, among others. CEO and Chairman of the Board of Directors of ZAP Imóveis for 12 years, Schaefer also led Grupo Globo’s Integrated Business area in 2018. Currently, he is a partner at Globo Ventures and a board member at Fundação Roberto Marinho, Proz Educação, and SuperBid.
                                </p>
                            </div>
                            <div className={styles['grid-item']}>
                                <img src="/images/news/july3/person2.png" alt="" />
                                <p className={styles['texts-july']}>
                                    Marcelo Bechara, who holds an MBA in Economics and Business Law specializing in IT and Media Law, served as a legal advisor to the Ministry of Communication, Attorney General and Commissioner of Anatel, and lent his talents to the Internet Management Committee in Brazil—CGI.br, where he helped draft the Brazilian Civil Rights Framework for the Internet. In addition to being an acting member of the Board of the IAB (Interactive Advertising Bureau Brazil), Bechara is Director of Institutional Relations and Media Regulation at Grupo Globo.
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="/images/news/july3/bottomCover3.png" alt="" />
                </section>
                <section className={styles.footer}>
                    <img className={styles.logo} src="/images/news/july3/logo.png" alt="" />
                    <div className={styles.socialIcons}>
                        <a href="https://www.linkedin.com/company/sthorm/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/icon_linkedin.svg" alt="" />
                        </a>
                        <a href="https://www.instagram.com/sthorm.io/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/icons/icon_instagram.svg" alt="" />
                        </a>
                    </div>
                </section>
            </div>
        </div >
    );
}