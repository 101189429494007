import '../../styles/styles.sass';
// import { Link } from 'react-router-dom';
import React, { useEffect, useState} from 'react'

import ShuffleText from 'react-shuffle-text'
import { motion } from 'framer-motion';

import { Coordinates } from '../../components/Coordinates'

export default function HomeContent () {
    // const ref = useRef();

    //////////////////////// Handling window size & resize ///////////////////////////////////////
    const [isMobile, setMobile] = useState(window.innerWidth < 600);

    const updateMedia = () => {
        setMobile(window.innerWidth < 600);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, [])

    useEffect(() => {
        //////////////////////// Animations Coordinates ///////////////////////////////////////
        const coordContainer = document.querySelector('.home__coordinates');
        const headerContainer = document.querySelector('.home__header');

        //Hide on mobile after a few seconds
        setTimeout(() => {
            coordContainer.classList.add('hiding')
            headerContainer.classList.add('positioned')
            setTimeout(() => {
                coordContainer.classList.add('hidden')
            }, 1000)
        }, 4500)

        //Translate on click projects menu
        document.querySelector('#projects-trigger').addEventListener('click', () => {
            if (coordContainer.classList.contains('translated')) {
                // console.log('active')
                setTimeout(() => {
                    coordContainer.classList.remove('translated');
                }, 600)
            } else {
                // console.log('inactive')
                coordContainer.classList.add('translated');
            }
        });
        
    }, [isMobile]);

    return (
        <>
            <motion.div 
                className="home page__wrapper"
                transition={{ duration: 0.5 }}
                initial={{ opacity: 0, x: '10%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '-10%' }}
            >
                <div className="home__wrapper">
                    <div className="home__header">
                        <h1 className="home__title title--display--01 font--black">We are a mission-driven special unit</h1>
                        <p className="home__description text--body--01">Leveraging blockchain technologies for the advancement and dissemination of applied sciences with social and environmental impact.</p>
                    </div>

                    <div className="home__coordinates coordinates">

                        <Coordinates />                       

                        <p className="coordinates__additional">
                            <ShuffleText content="Internet" />
                            <ShuffleText content="souls" />
                            <ShuffleText content="LatAm roots" />
                        </p>
                    </div>

                    {isMobile ? (
                        <div className="home__image">
                            <picture>
                                <source media="(min-width: 1921px)" srcSet="/images/home/home_image_2x.png" />
                                <source media="(min-width: 801px) and (max-width: 1920px)" srcSet="/images/home/home_image_2x.png 2x, /images/home/home_image_1x.png 1x" />
                                <source media="(max-width: 800px)" srcSet="/images/home/home_image_sm.png" />
                                <img src="/images/home/home_image_1x.png" alt=""/>
                            </picture>
    
                        </div>
                    ) : (
                        <></>

                    )}

                    
                </div>
            </motion.div>
        </>
    )
}