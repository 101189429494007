export default function ColorScheme() {
    const body = document.body;
    const lightTheme = "light";
    const darkTheme = "dark";
    let theme;

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) { 
        theme = "dark"
    } else {
        theme = "light"
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        theme = event.matches ? "dark" : "light";
    });
    
    if (theme === lightTheme || theme === darkTheme) {
        body.classList.add(theme + "-theme");
    } 
};